import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { ReactNode } from 'react';
import logo from 'images/logo.png';
import { Link } from 'components';

export function InfoPage({ children }: { children: ReactNode }) {
  return (
    <Box
      component="main"
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: grey[200],
        px: 2,
      }}>
      <Link
        href="/dashboard"
        sx={{
          textDecoration: 'none',
          fontSize: '1.2rem',
          display: 'flex',
          fontFamily: 'Fira Sans Condensed',
          fontWeight: 500,
          color: 'primary.main',
          mb: 2,
          '& > img': {
            width: 24,
            height: 24,
            marginRight: 1,
          },
        }}>
        <img src={logo} />
        <span>PARTNERS IN PARENTING</span>
      </Link>

      <Typography
        sx={{
          textTransform: 'uppercase',
          color: grey[600],
          textAlign: 'center',
        }}
        variant="h6">
        {children}
      </Typography>
    </Box>
  );
}
