import { ReactNode, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from 'app/store';
import { navigate } from 'gatsby';
import { use100vh } from 'react-div-100vh';

export function AuthPage({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const { userInfo, loaded } = useAppSelector((state) => state.auth);
  const height = use100vh();

  useEffect(() => {
    if (loaded && userInfo !== null) {
      navigate('/dashboard', { replace: true });
    }
  }, [loaded, userInfo]);

  if (!loaded || userInfo !== null) {
    return null;
  }

  return (
    <Box
      component="article"
      sx={{
        width: '100vw',
        height: height,
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}>
      {children}
    </Box>
  );
}
