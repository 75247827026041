import { Typography } from '@mui/material';
import { ensureAuth } from 'app/helpers/ensureAuth';
import { AuthPage } from 'app/layout/AuthPage';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

const Index = () => {
  useEffect(() => {
    navigate('/auth/signin');
  }, []);
  return (
    <AuthPage>
      <Typography>Redirecting...</Typography>
    </AuthPage>
  );
};

export default ensureAuth(Index);
