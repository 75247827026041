import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { fetchProfile, useAppDispatch, useAppSelector } from 'app/store';
import { useAsyncState } from 'app/hooks';

import type { ComponentType, ComponentProps } from 'react';
import { InfoPage } from '../layout/InfoPage';

const authRedirect = '/auth/signin';

const profileRedirect = '/dashboard/createProfile';

export function ensureAuth<T extends {}>(Component: ComponentType<T>, redirectToCreateProfile: boolean = true) {
  return (props: ComponentProps<typeof Component>) => {
    const dispatch = useAppDispatch();
    const [state, success, failure, waiting] = useAsyncState();
    const { auth, profile } = useAppSelector((state) => state);

    // wait for auth to be loaded
    useEffect(() => {
      if (auth.loaded) {
        // not authenticated, redirect to signin
        if (auth.userInfo === null) {
          navigate(authRedirect, { replace: true });
          return;
        }

        // load profile
        if (!profile.loaded) {
          dispatch(fetchProfile()).unwrap().then(success, failure);
          waiting();
        }
      }
    }, [auth, profile]);

    // redirect to create profile if loading profile failed
    useEffect(() => {
      if (redirectToCreateProfile && state.status === 'failure') {
        navigate(profileRedirect);
      }
    }, [state, redirectToCreateProfile]);

    if (auth.userInfo === null) {
      // wait for auth to be resolved
      return null;
    }

    if (state.status === 'pending') {
      // wait loading profile
      return <InfoPage>Loading profile</InfoPage>;
    }

    if (redirectToCreateProfile && profile.data === null) {
      return <InfoPage>User profile can not be loaded...</InfoPage>;
    }

    return <Component {...props} />;
  };
}
